import React from "react"
import styled from "styled-components"
import { rgba } from "polished"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import { Title, Section, Text, Span } from "../components/Core"
import { device } from "../utils"
import ConditionsList from "../sections/conditions/ConditionsList.js"
import HeroComponent from "../components/ImageHero.js"
import imgVideo from "../assets/image/jpeg/active-acne.jpg"
import Trend from "../components/trends.js"
import Saveface from "../components/save.js"
import Clinic from '../components/NewFind.js'; 

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before,
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`




const BAlacneHesroPage = () => (
  <>
    {/* <!-- Content section 1 --> */}

    <HeroComponent
        itext='<span style="color: #1a1a1a;">Conditions</span> <span style="color: #00aec7;">List</span>'
        secondText="Symptoms, Causes, and Treatments for conditions"
        videoAlt="Conditions Treatments"
        imgVideo={imgVideo} // Specify your image URL here
      />
    <Separator />
      <Section bg="#f7f7fb" py={4}>
        <Container className="pt-3 pb-3"  data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="true">
          <Row className="pb-0 pt-4 align-items-center">
            <Col lg="12" className="order-lg-1 mt-lg-0  pb-lg-5">
            
               <ConditionsList />
            </Col>
          </Row>
        </Container>
      </Section>
      <SeparatorEnd />
      <Clinic />
    <Saveface />
    <Trend/>
  </>
)

export default BAlacneHesroPage
