import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Link from 'gatsby-link';

import { Section, Box } from "../../components/Core";
import PostCard from "../../components/PostCard";


import imgB1 from "../../assets/image/jpeg/acne5.jpg";
import imgB2 from "../../assets/image/jpeg/black1.jpg";
import imgB3 from "../../assets/image/jpeg/crow1.jpg";
import imgB4 from "../../assets/image/jpeg/dark1.jpg";
import imgB5 from "../../assets/image/jpeg/hair1.jpg";
import imgB6 from "../../assets/image/jpeg/sweating1.jpg";
import imgB7 from "../../assets/image/jpeg/forehead1.jpg";
import imgB8 from "../../assets/image/jpeg/ingrown1.jpg";
import imgB9 from "../../assets/image/jpeg/hyper1.jpg";
import imgB10 from "../../assets/image/jpeg/jowl1.jpg";
import imgB11 from "../../assets/image/jpeg/laughter1.jpg";
import imgB12 from "../../assets/image/jpeg/moles1.jpg";
import imgB13 from "../../assets/image/jpeg/milia1.jpg";
import imgB14 from "../../assets/image/jpeg/neck1.jpg";
import imgB15 from "../../assets/image/jpeg/spots1.jpg";
import imgB16 from "../../assets/image/jpeg/stretch1.jpg";
import imgB17 from "../../assets/image/jpeg/thinlips1.jpg";
import imgB18 from "../../assets/image/jpeg/veins1.jpg";
import imgB19 from "../../assets/image/jpeg/under1.jpg";
import imgB20 from "../../assets/image/jpeg/weak1.jpg";
import imgB21 from "../../assets/image/jpeg/jawline1.jpg";
import imgB22 from "../../assets/image/jpeg/prp-hair-blog.jpg";
import imgB23 from "../../assets/image/jpeg/hydra-blog.jpg";
import imgB24 from "../../assets/image/jpeg/fillers2.jpg";
import imgB25 from "../../assets/image/jpeg/nose-filler-blog.jpg";
import imgB26 from "../../assets/image/jpeg/beard2.jpg";
import imgB27 from "../../assets/image/jpeg/blog-prp.jpg";
import imgB28 from "../../assets/image/jpeg/vampire-facial.jpg";
import imgB29 from "../../assets/image/jpeg/facial.jpg";
import imgB30 from "../../assets/image/jpeg/mesotherapy.jpg";
import imgB31 from "../../assets/image/jpeg/about-derma-clinic.jpg";



const BlogList = () => (
  <>
    {/* <!-- Blog section --> */}
  
      <Container>
        <Row className="align-items-center justify-content-center">

    
    

        <Col lg="4" xs="6" className="mb-5">
            <Link to="/acne-active-acne">
            <PostCard
              img={imgB1}
            
              title="Acne / Active Acne">
         
            </PostCard>
            </Link>
          </Col>

        <Col lg="4" xs="6" className="mb-5">
            <Link to="/blackheads">
            <PostCard
              img={imgB2}
            
              title="Blackheads">
         
            </PostCard>
            </Link>
          </Col>


        <Col lg="4" xs="6" className="mb-5">
            <Link to="/crows-feet">
            <PostCard
              img={imgB3}
            
              title="Crow's Feet">
         
            </PostCard>
            </Link>
          </Col>
          
        <Col lg="4" xs="6" className="mb-5">
            <Link to="/dark-circles">
            <PostCard
              img={imgB4}
            
              title="Dark Circles">
         
            </PostCard>
            </Link>
          </Col>

        <Col lg="4" xs="6" className="mb-5">
            <Link to="/excess-hair">
            <PostCard
              img={imgB5}
            
              title="Excess Hair">
         
            </PostCard>
            </Link>
          </Col>

          <Col lg="4" xs="6" className="mb-5">
            <Link to="/excess-sweating">
            <PostCard
              img={imgB6}
            
              title="Excess Sweating">
         
            </PostCard>
            </Link>
          </Col>
          <Col lg="4" xs="6" className="mb-5">
          <Link to="/forehead-lines">
            <PostCard
              img={imgB7}
              
              title="Forehead Lines"
         
            >
            
            </PostCard>
            </Link>
          </Col>
          <Col lg="4" xs="6" className="mb-5">
          <Link to="/ingrown-hairs">
            <PostCard
              img={imgB8}
             
              title="Ingrown Hairs"
           
            >
              
            </PostCard>
            </Link>
          </Col>

          
          <Col lg="4" xs="6" className="mb-5">
          <Link to="/hyperpigmentation">

            <PostCard
              img={imgB9}
         
              title="Hyperpigmentation"
            
            >
            
            </PostCard>
            </Link>
          </Col>
          <Col lg="4" xs="6" className="mb-5">
          <Link to="/jowls">
            <PostCard
              img={imgB10}
        
              title="Jowls"
             
            >
            
            </PostCard>
            </Link>
          </Col>
          <Col lg="4" xs="6" className="mb-5">
          <Link to="/laughter-lines">
            <PostCard
              img={imgB11}
             
              title="Laughter Lines"
           
            >
              
            </PostCard>
            </Link>
          </Col>
          <Col lg="4" xs="6" className="mb-5">
          <Link to="/moles">
            <PostCard
              img={imgB12}
              
              title="Moles"
    
            >
      
            </PostCard>
            </Link>
          </Col>
       
          <Col lg="4" xs="6" className="mb-5">
          <Link to="/milia">
            <PostCard
              img={imgB13}
             
              title="Milia"
           
            >
              
            </PostCard>
            </Link>
          </Col>

       
          <Col lg="4" xs="6" className="mb-5">
          <Link to="/neck-lines">
            <PostCard
              img={imgB14}
             
              title="Neck Lines"
           
            >
              
            </PostCard>
            </Link>
          </Col>
          <Col lg="4" xs="6" className="mb-5">
          <Link to="/spots">
            <PostCard
              img={imgB15}
             
              title="Spots"
           
            >
              
            </PostCard>
            </Link>
          </Col>

          <Col lg="4" xs="6" className="mb-5">
          <Link to="/stretch-marks">
            <PostCard
              img={imgB16}
             
              title="Stretch Marks"
           
            >
              
            </PostCard>
            </Link>
          </Col>

          <Col lg="4" xs="6" className="mb-5">
          <Link to="/thin-lips">
            <PostCard
              img={imgB17}
             
              title="Thin Lips"
           
            >
              
            </PostCard>
            </Link>
          </Col>

          <Col lg="4" xs="6" className="mb-5">
          <Link to="/thread-veins">
            <PostCard
              img={imgB18}
             
              title="Thread Veins"
           
            >
              
            </PostCard>
            </Link>
          </Col>

          <Col lg="4" xs="6" className="mb-5">
          <Link to="/under-eye-wrinkles">
            <PostCard
              img={imgB19}
             
              title="Under Eye Wrinkles"
           
            >
              
            </PostCard>
            </Link>
          </Col>

         

          <Col lg="4" xs="6" className="mb-5">
          <Link to="/weak-chin">
            <PostCard
              img={imgB20}
             
              title="Weak Chin"
           
            >
              
            </PostCard>
            </Link>
          </Col>


          <Col lg="4" xs="6" className="mb-5">
          <Link to="/weak-jawline">
            <PostCard
              img={imgB21}
             
              title="Weak Jawline"
           
            >
              
            </PostCard>
            </Link>
          </Col>
    



        </Row>
     
      </Container>
  
  </>
);

export default BlogList;
